import React, { Component } from 'react';
import Image from 'next/image';

class Thanks extends Component {
  constructor(props) {
    super(props);
  }
  // static getDerivedStateFromProps(props,state){

  //     if(process.browser){

  //         console.log(props);

  //         if(props.show ){
  //             document.body.style.overflow = 'hidden';
  //         }
  //         else if(!props.show && props.loaded){

  //             document.body.style.overflow = 'auto';
  //             document.body.style.overflowX = 'hidden';

  //         }

  //     }

  //     return null;

  // }

  render() {
    var page = '';

    return (
      <div
        className={
          'fixed z-900 bg-navbar w-full h-full left-0 top-0 right-0 bottom-0 items-center justify-center' +
          ' ' +
          (this.props.show ? 'flex' : 'hidden')
        }
      >
        <div className="absolute left-0 top-0 w-full h-full" onClick={this.props.hide} />
        <div
          className={`relative w-10/12 ${this.props.from == 'network' ? 'max-w-650' : 'max-w-650'} d bg-gray border border-gray-transparent rounded-5`}
        >
          <div
            onClick={this.props.hide}
            className="absolute -right-12 -top-12 bg-purple text-white w-30 h-30 text-21 rounded-full flex items-center justify-center cursor-pointer select-none"
          >
            <span>X</span>
          </div>
          <div className="p-23 md:p-30 text-center">
            {this.props.loaded ? (
              <>
                <div className="text-center">
                  <Image src="/images/thank-you.png" alt="thank-you" width={259} height={220} />
                </div>

                <div className="text-black text-16 md:text-18 text-center">{this.props.children}</div>

                <p className="text-purple uppercase text-36 md:text-48 text-center font-black">{this.props.tnx ? this.props.tnx : 'Thank you'}</p>
                {this.props.hideBackButton ? (
                  ''
                ) : (
                  <a
                    href={`${this.props.backUrl ? this.props.backUrl : 'https://weprodigi.com/'}`}
                    className="inline-block text-white bg-green rounded-5 text-16 md:text-18 py-9 px-30 mt-23"
                  >
                    {this.props.goBack ? this.props.goBack : 'Back Home'}
                  </a>
                )}
              </>
            ) : (
              <p className="text-black text-center">Loading...</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default Thanks;
