import React, { Component } from 'react';

import Thanks from './Thanks';

import { Input, Textarea } from './forms/input';
import ReCAPTCHA from 'react-google-recaptcha';
const NEXT_PUBLIC_RECAPTCHA_SITE_KEY = '6Le-UtseAAAAAGpB4ucUJNIvZslgvZXc77lRT3tG';

import axios from 'axios';

class Offer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formdata: {},
      showThanks: false,
      loadedThanks: false,
      title: props.ti,
      titleService: null,
      show: props.show,
      nda: 'No',
      services: [],
    };
    this.reRef = React.createRef();

    this.handleChange = this.handleChange.bind(this);

    this.hideThanksModal = this.hideThanksModal.bind(this);
  }

  handleChange(param, value) {
    if (param == 'nda') {
      this.setState({ nda: value });
    }
    if (param == 'service') {
      let checkboxes = document.getElementsByName('service');
      let checkboxesChecked = [];

      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      if (checkboxesChecked.length > 0) {
        this.setState({
          titleService: 'Digital Services',
          services: checkboxesChecked.join(','),
        });
      } else {
        this.setState({ titleService: null, services: '' });
      }
    }
    this.setState(prevState => {
      let formdata = Object.assign({}, prevState.formdata);
      formdata[param] = value;
      return { formdata };
    });
  }
  hideThanksModal() {
    this.setState({
      showModal: false,
      titleService: null,
      showThanks: false,
      loadedThanks: false,
    });
    document.body.style.overflow = 'auto';

    document.body.style.overflowX = 'hidden';
  }
  onSubmit(e) {
    e.preventDefault();

    const token = this.reRef.current.getValue();

    if (token) {
      var that = this;

      var form_data = new FormData(e.target);

      for (var key in this.state.formdata) {
        form_data.append(key, this.state.formdata[key]);
      }

      if (this.state.titleService) form_data.append('title', this.state.titleService);
      else form_data.append('title', this.state.formdata.service);

      form_data.append('service', this.state.services);
      form_data.append('country', this.props.country);

      // that.props.hide();

      // that.setState({showThanks:true});

      document.body.style.overflow = 'hidden';
      if (this.state.services.length > 0) {
        axios.post('https://mailer.weprodigi.com/actions/6New.php', form_data).then(response => {
          // that.setState({loadedThanks:true,services:[]});
          window.location.href = 'https://weprodigi.com/thank-you';
        });
      }
    }
    this.reRef.current.reset();
    // e.target.reset()
  }
  hide = () => {
    this.setState({
      showModal: false,
      titleService: null,
      showThanks: false,
      loadedThanks: false,
    });
    // document.getElementById('offer-form').reset()
    this.props.hide();
  };
  render() {
    return (
      <>
        <div
          className={
            'fixed z-900 bg-navbar w-full h-full left-0 top-0 right-0 bottom-0 items-center justify-center' +
            ' ' +
            (this.props.show && !this.state.showThanks ? 'flex' : 'hidden')
          }
        >
          <div className="absolute left-0 top-0 w-full h-full" onClick={this.hide}></div>

          <div className="relative transform w-11/12 max-w-screen-md md:max-w-800 max-h-screen-md bg-gray border border-gray-transparent rounded-5 md:scale-95 2xl:scale-100">
            <div
              onClick={this.hide}
              className="absolute -right-12 -top-12 bg-purple text-white w-30 h-30 text-21 rounded-full flex items-center justify-center cursor-pointer select-none"
            >
              <span className="font-bold">X</span>
            </div>

            <div className="py-23 px-50 h-600 overflow-y-auto ">
              <p className="text-purple uppercase text-30 text-center font-black leading-none">Get a proposal</p>
              {/* <p className="text-black uppercase text-18 text-center  font-black leading-none mt-15">Digital Services</p> */}

              <form id={!this.props.id ? 'offer-form' : 'form-offer'} method="POST" onSubmit={e => this.onSubmit(e)}>
                <div className="mt-10">
                  <div className="mb-10">
                    <p className="text-16 text-purple font-medium mb-10">1. Which services are you more interested in? *</p>
                    <div className="flex flex-wrap">
                      <div className="form-group mr-5  ml-10">
                        <label className="relative pl-30 mt-10 cursor-pointer text-14 flex items-center">
                          <input
                            className="absolute cursor-pointer opacity-0"
                            onChange={() => this.handleChange('service', 'Video Production')}
                            type="checkbox"
                            name="service"
                            required={this.state.formdata.service?.length == 0 || !this.state.formdata.service}
                            value="Video Production"
                          />
                          <span className="checkmark absolute left-0 w-20 h-20 border border-purple rounded-sm" />
                          Video Production
                        </label>
                        <label className="relative pl-30  mt-10 cursor-pointer text-14 flex items-center">
                          <input
                            className="absolute cursor-pointer opacity-0"
                            onChange={() => this.handleChange('service', 'Social Media Marketing')}
                            type="checkbox"
                            name="service"
                            required={this.state.formdata.service?.length == 0 || !this.state.formdata.service}
                            value="Social Media Marketing"
                          />
                          <span className="checkmark absolute left-0 w-20 h-20 border border-purple rounded-sm" />
                          Social Media Marketing
                        </label>
                      </div>
                      <div className="form-group mr-5  ml-10">
                        <label className="relative pl-30 mt-10 cursor-pointer text-14 flex items-center">
                          <input
                            className="absolute cursor-pointer opacity-0"
                            onChange={() => this.handleChange('service', 'Paid advertisement')}
                            type="checkbox"
                            name="service"
                            required={this.state.formdata.service?.length == 0 || !this.state.formdata.service}
                            value="Paid Advertising Services"
                          />
                          <span className="checkmark absolute left-0 w-20 h-20 border border-purple rounded-sm" />
                          Paid Advertising Services
                        </label>
                        <label className="relative pl-30  mt-10 cursor-pointer text-14 flex items-center">
                          <input
                            className="absolute cursor-pointer opacity-0"
                            onChange={() => this.handleChange('service', 'Development')}
                            type="checkbox"
                            name="service"
                            required={this.state.formdata.service?.length == 0 || !this.state.formdata.service}
                            value="Website Development"
                          />
                          <span className="checkmark absolute left-0 w-20 h-20 border border-purple rounded-sm" />
                          Website Development
                        </label>
                      </div>
                      <div className="form-group mr-5  ml-10">
                        <label className="relative pl-30 mt-10 cursor-pointer text-14 flex items-center">
                          <input
                            className="absolute cursor-pointer opacity-0"
                            onChange={() => this.handleChange('service', 'SEO')}
                            type="checkbox"
                            name="service"
                            required={this.state.formdata.service?.length == 0 || !this.state.formdata.service}
                            value="SEO"
                          />
                          <span className="checkmark absolute left-0 w-20 h-20 border border-purple rounded-sm"></span>
                          SEO
                        </label>
                      </div>
                    </div>

                    <p className=" ml-10 text-14 text-black mb-5 mt-20">Does your company have a website?</p>

                    <div className="flex flex-row my-10 ml-10">
                      <div className="form-group mr-30">
                        <label className="relative pl-30 cursor-pointer text-14 flex items-center">
                          <input
                            className="absolute cursor-pointer opacity-0"
                            onChange={() => this.handleChange('has-website', 'Yes')}
                            type="radio"
                            name="has-website"
                            value="Yes"
                          />
                          <span className="checkmark absolute left-0 w-20 h-20 border border-purple rounded-sm"></span>
                          Yes
                        </label>
                      </div>

                      <div className="form-group mr-30">
                        <label className="relative pl-30 cursor-pointer text-14 flex items-center">
                          <input
                            className="absolute cursor-pointer opacity-0"
                            onChange={() => this.handleChange('has-website', 'No')}
                            type="radio"
                            name="has-website"
                            value="No"
                          />
                          <span className="checkmark absolute left-0 w-20 h-20 border border-purple rounded-sm"></span>
                          No
                        </label>
                      </div>
                    </div>

                    {this.state.formdata['has-website'] == 'Yes' && (
                      <div>
                        <p className="text-14 text-black mb-5">Website URL*</p>

                        <div className="mb-10">
                          <Input type="text" name="website" bg="bg-white" required={true} onChange={this.handleChange}></Input>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="mb-10">
                    <p className="text-16 text-purple font-medium mb-20">2. Is there anything specific we should know about your project ?</p>

                    {/* <p className="text-14 text-black mb-5"></p> */}

                    <div className="mb-10">
                      <Textarea
                        type="text"
                        plColor="#B2B2BF"
                        name="description"
                        resize="resize-none	"
                        bg="bg-gray-light"
                        required={false}
                        rows={2}
                        placeholder="Background, requirements, budget, deadline, etc."
                        onChange={this.handleChange}
                      ></Textarea>
                    </div>
                  </div>

                  <div className="mb-10">
                    <p className="text-16 text-purple font-medium mb-10">3. Tell us how to contact you</p>

                    <p className="text-14 text-black mb-5">Your Name*</p>

                    <div className="mb-10">
                      <Input type="text" name="name" bg="bg-gray-light" required={true} onChange={this.handleChange}></Input>
                    </div>

                    <div className="grid grid-cols-2 gap-10">
                      <div>
                        <p className="text-14 text-black mb-5">Your Email*</p>

                        <div className="mb-10">
                          <Input type="text" name="email" bg="bg-gray-light" required={true} onChange={this.handleChange}></Input>
                        </div>
                      </div>

                      <div>
                        <p className="text-14 text-black mb-5">Your Phone Number</p>

                        <div className="mb-10">
                          <Input type="text" name="number" bg="bg-gray-light" required={false} onChange={this.handleChange}></Input>
                        </div>
                      </div>
                    </div>
                    <div className=" flex flex-wrap">
                      <div className="w-full md:w-half-auto">
                        <p className="text-14 text-black mb-5">What’s the best way to contact you?</p>

                        <div className="flex flex-row my-10">
                          <div className="form-group mr-10">
                            <label className="relative pl-30 cursor-pointer text-14 flex items-center">
                              <input
                                className="absolute cursor-pointer opacity-0"
                                onChange={() => this.handleChange('contact-via', 'Email')}
                                type="radio"
                                name="contact-via"
                                value="Email"
                              ></input>
                              <span className="checkmark absolute left-0 w-20 h-20 border border-purple rounded-sm"></span>
                              Email
                            </label>
                          </div>

                          <div className="form-group mr-10">
                            <label className="relative pl-30 cursor-pointer text-14 flex items-center">
                              <input
                                className="absolute cursor-pointer opacity-0"
                                onChange={() => this.handleChange('contact-via', 'Phone')}
                                type="radio"
                                name="contact-via"
                                value="Phone"
                              ></input>
                              <span className="checkmark absolute left-0 w-20 h-20 border border-purple rounded-sm"></span>
                              Phone
                            </label>
                          </div>

                          <div className="form-group mr-10">
                            <label className="relative pl-30 cursor-pointer text-14 flex items-center">
                              <input
                                className="absolute cursor-pointer opacity-0"
                                onChange={() => this.handleChange('contact-via', 'Both')}
                                type="radio"
                                name="contact-via"
                                value="Both"
                              ></input>
                              <span className="checkmark absolute left-0 w-20 h-20 border border-purple rounded-sm"></span>
                              Both
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:w-half-auto ml-20">
                        <p className="text-14 text-black mb-5">NDA needed</p>

                        <div className="flex flex-row my-10">
                          <div className="form-group mr-10">
                            <label className="relative pl-30 cursor-pointer text-14 flex items-center">
                              <input
                                className="absolute cursor-pointer opacity-0"
                                onChange={() => {
                                  if (this.state.nda == 'No') this.handleChange('nda', 'Yes');
                                  else this.handleChange('nda', 'No');
                                }}
                                type="checkbox"
                                name="nda"
                              />
                              <span className="checkmark absolute left-0 w-20 h-20 border border-purple rounded-sm" />
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row my-10">
                        <ReCAPTCHA sitekey={NEXT_PUBLIC_RECAPTCHA_SITE_KEY} size={'normal'} ref={this.reRef} />
                      </div>
                    </div>
                  </div>

                  <input type="hidden" name="country" value={this.props.country}></input>

                  <div className="col-span-2">
                    <button
                      id="offer-submit"
                      type="submit"
                      className="cursor-pointer mx-auto text-white bg-green text-18 mt-10 px-23 py-3 rounded-5 block "
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Thanks show={this.state.showThanks} loaded={this.state.loadedThanks} hide={this.hideThanksModal}>
          Thank you for reaching out to us! One of our account managers will contact you and confirm your request. If you don’t receive an email from
          us within 24 hours please check your Spam Box.
        </Thanks>
      </>
    );
  }
}
export default Offer;
